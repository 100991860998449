import React from "react"
import Layout from "../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import PartnerCarousel from "../../components/PartnerCarousel"
import PeaceOfMindGuarantee from "../../components/PeaceOfMindGuarantee"
import SectionTitle from "../../components/SectionTitle"
import { Banner } from "../../components/Banner"
import EducationOffers from "../../components/education/EducationOffers"
import FeatureImageWithDescription from "../../components/FeatureImageWithDescription"
import SEO from "../../components/seo"
import Accordions from "../../components/Accordion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBuilding,
  faInfo,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons"

export default function FinanceOnly() {
  const data = useStaticQuery(graphql`
    query educationFinanceOnly {
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_297400808.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      RunOutOfBudget: file(
        relativePath: { eq: "banner/AdobeStock_176591907.jpeg" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  const FAQs = [
    {
      title: "How does it work?",
      desc: (
        <>
          <p>
            It’s simple. Utilising our relationships with banks and funders,
            Compulease™ will go out to the financial market on an individual
            deal-by-deal basis and “buy in” the money for your specific deal at
            a guaranteed preferential price.
          </p>
          <p>
            All the school has to do is to provide us with a copy of their
            existing quote, the capital cost involved and the name of their
            chosen supplier. Compulease™ will do the rest.
          </p>
          <p>No change to your current suppliers</p>
        </>
      ),
      icon: <FontAwesomeIcon icon={faQuestion} />,
    },
    {
      title: "What do we need from the school?",
      desc: (
        <>
          <p>
            As we go out to the financial markets specifically for your
            transaction, it is a “bespoke” service and as such we don’t provide
            quotations, just a written confirmation of the final payments and
            the money you will SAVE!
          </p>
          <p>
            All the school has to do is to provide us with proof of their
            existing quote which must be from a bonafide finance company, the
            capital cost involved and the name of their chosen supplier.
            Compulease™ will do the rest. The service is guaranteed to provide
            you a saving.
          </p>
        </>
      ),
      icon: <FontAwesomeIcon icon={faBuilding} />,
    },
    {
      title: "Why should you use this service?",
      desc: (
        <p>
          Because we have NO vested interest in the supplier or the goods they
          are supplying, our only aim is to save your school money.
        </p>
      ),
      icon: <FontAwesomeIcon icon={faInfo} />,
    },
  ]

  return (
    <Layout headerType="education">
      <SEO title="Compulease · Finance Only Education" />
      <EducationOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              title="Finance Only"
              subtitle="A Value-Added Service For The Education Sector"
              description={
                <>
                  <h3>
                    In the event you want to work with your existing suppliers,
                    Compulease™ can save your school money with our finance only
                    facility.
                  </h3>
                  <p>
                    Due to our vast buying power and long term relationships
                    with banks and funders, we have access to significantly
                    discounted lease rates for exclusive use within schools and
                    colleges.
                  </p>
                  <p>
                    We can deal with all of your current suppliers so you won’t
                    see any changes, only SAVINGS!
                  </p>
                </>
              }
            />
          </Col>
          <Col md={{ span: "8", offset: "2" }}>
            <Accordions items={FAQs} fullWidth />
          </Col>
        </Row>
        <Banner
          image={data.BannerImage.childImageSharp.fluid}
          title="We can provide education finance on any equipment within the school such as kitchens, science equipment, design technology equipment, astro-turf pitches and even double-glazing!"
          link={{
            text: "See our Bespoke solutions",
            to: "/education/Bespoke/",
          }}
        />
        <FeatureImageWithDescription
          title="Run out of budget?"
          subtitle="Compulease™ Can Help"
          listDescription={[
            "Making a budget meet the necessary requirements of the teaching environment can be challenging to say the least and it is not at all uncommon for a school to find it still has remaining and urgent needs once the budget has been spent.",
            "Compulease™ can help by buying back from the school, equipment it has already purchased and leasing it back to them.",
            "This process is called “sale & leaseback” and it releases valuable additional capital to the school for immediate use in whichever area you need: books, school dinners, support staff etc.",
          ]}
          image={data.RunOutOfBudget.childImageSharp.fluid}
        />
        <PeaceOfMindGuarantee />
      </Container>
      <PartnerCarousel type="education" />
    </Layout>
  )
}
